import React from 'react';

const Checkbox = ({ value, setFieldValue, errorText, floatingLabel, ...rest }) => (
  <div className="Checkbox" onClick={() => setFieldValue(rest.name, !value)}>
    <input className="Checkbox-input" checked={value} type="checkbox" onChange={() => {}} />
    <label htmlFor={rest.name}>
      <span className="Checkbox-label" dangerouslySetInnerHTML={{ __html: floatingLabel }} />
    </label>
    {errorText && <span className="Checkbox-error">{errorText}</span>}
  </div>
);

export default Checkbox;
