import React from 'react';
import { Field } from 'formik';
import Checkbox from './Checkbox';

const CheckboxWrapper = ({ field, form: { touched, errors, setFieldValue }, ...rest }) => (
  <Checkbox
    {...field}
    errorText={touched[field.name] && errors[field.name]}
    {...rest}
    floatingLabel={rest.floatingLabel}
    setFieldValue={setFieldValue}
  />
);

const FormikCheckbox = ({ name, ...rest }) => (
  <Field type="checkbox" name={name} component={CheckboxWrapper} {...rest} />
);

export default FormikCheckbox;
