import React, { useState } from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import { values as ramdaValues } from 'ramda';

import Modal from './Modal';
import SubmitButton from './SubmitButton';
import FormikTextField from './FormikTextField';
import FormikCheckbox from './FormikCheckbox';
import { registrationFormScheme } from '../utils/validations';
import { postData, getData } from '../utils/fetch';
import { isStringTrue, isStringFalse } from '../utils/truthChecks';

const RegistrationForm = ({ pageContext, hasDiscount }) => {
  const [gdprChecked, setGdprChecked] = useState(false);
  const [modalText, setModalText] = useState(undefined);

  const isOnWaitingList = ({ waitingList }) => isStringTrue(waitingList);
  const registeredUsers = ramdaValues(pageContext.registered).filter(({ waitingList }) =>
    isStringFalse(waitingList)
  );
  const numOfUsersOnWaitingList = ramdaValues(pageContext.registered).filter(
    isOnWaitingList
  ).length;
  const isWorkshopFull =
    pageContext.maxUsers && Object.keys(registeredUsers).length >= Number(pageContext.maxUsers);
  const ifOnWaitingList = (a, b) => (isWorkshopFull ? b : a);
  return (
    <>
      {modalText && <Modal closeModal={() => setModalText(undefined)} text={modalText} />}

      <Formik
        initialValues={{
          name: '',
          email: '',
          street: '',
          city: '',
          agreement: false,
          company: '',
          phone: '',
          personId: '',
          postalCode: '',
        }}
        validationSchema={registrationFormScheme}
        onSubmit={(values, formikActions) => {
          postData(`${process.env.FIREBASE_API_URL}/public/workshop/request`, {
            ...values,
            discount: hasDiscount,
            priceOrDiscountedPrice:
              hasDiscount === true ? pageContext.discountedPrice : pageContext.price,
            workshopKey: pageContext.key,
            dateOfRegistration: dayjs().format('DD.MM.YYYY HH:mm'),
            waitingList: ifOnWaitingList('false', 'true'),
          }).then(() => {
            if (gtag) {
              gtag('event', 'conversion', { send_to: 'AW-604602367/YsFYCLPhwb0CEP__paAC' });
            }
            formikActions.setSubmitting(false);
            formikActions.resetForm();
            const text = ifOnWaitingList(
              `Vaše registrace na školení/workshop proběhla úspěšně. Během několika minut obdržíte na Vámi zadaný email potvrzení s platebními údaji.`,
              `Právě jste se přihlásil jako náhradník na ${pageContext.type} ${pageContext.name}. Během chvíle od nás obdržíte na Vámi uvedený e-mail podrobné informace.`
            );
            setModalText(text);
          });
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="ContactForm">
            <FormikTextField name="name" floatingLabel="formName" />
            <FormikTextField
              name="email"
              floatingLabel="formEmail"
              onBlur={() => {
                getData(`${process.env.FIREBASE_API_URL}/public/gdpr/check/${values.email}`)
                  .then((response) => {
                    if (response.error) {
                      setGdprChecked(false);
                      setFieldValue('agreement', false);
                    } else {
                      setGdprChecked(true);
                      setFieldValue('agreement', true);
                    }
                  })
                  .catch(() => {
                    setGdprChecked(false);
                    setFieldValue('agreement', false);
                  });
              }}
            />

            <FormikTextField name="street" floatingLabel="formStreet" />
            <FormikTextField name="city" floatingLabel="formCity" />
            <FormikTextField name="postalCode" floatingLabel="formPostalCode" />
            <FormikTextField name="phone" floatingLabel="formPhone" />

            <h3 className="Section-subTitle">Objednávám na firmu</h3>
            <FormikTextField name="company" floatingLabel="formCompany" />
            <FormikTextField name="personId" floatingLabel="formPersonId" />

            {!gdprChecked && (
              <FormikCheckbox
                name="agreement"
                floatingLabel={
                  'Souhlasím, aby společnost SQN s.r.o., se sídlem Kubelíkova 1224/42, 130 00 Praha 3, IČ 04965850, (dále jen „správce údajů”), zpracovávala mnou poskytnuté osobní údaje, a to v souladu s obecným nařízením o ochraně osobních údajů [EU] 2016/679. Pravidla o ochraně osobních údajů naleznete <a href="/gdpr-rules" target="_blank">zde</a>.'
                }
              />
            )}
            <FormikTextField
              name="country"
              floatingLabel="formCountry"
              style={{ visibility: 'hidden', position: 'absolute' }}
            />
            {isWorkshopFull && (
              <>
                <h3 className="Registration-subTitleRed">
                  Je nám líto, ale kapacita kurzu je již vyčerpána. Můžete se přihlásit jako
                  náhradník a o uvolněném místu Vás budeme neprodleně informovat.
                </h3>
                <h3 className="Registration-subTitleRed">{`Počet náhradníků před Vámi je: ${numOfUsersOnWaitingList}`}</h3>
              </>
            )}
            <SubmitButton
              isSubmitting={isSubmitting}
              sendMsg={ifOnWaitingList('logIn', 'logInWaitingList')}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

export default RegistrationForm;
