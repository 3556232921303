import React, { createElement } from 'react';
import { FormattedMessage } from 'react-intl';

const Button = ({ label, useLink, inverted = false, ...other }) => {
  const childrenFragment = (
    <>
      <FormattedMessage id={label} />
      <svg className="Button-icon" viewBox="0 0 17 10" width="17px" height="10px">
        <path
          fill={inverted ? '#FA2544' : '#fff'}
          d="M11.8,1.678l1.424,1.484A.494.494,0,0,1,12.861,4H.834C.277,4,0,4.448,0,5V4.992C0,5.545.277,6,.834,6H12.846a.506.506,0,0,1,.363.857l-1.416,1.46a.989.989,0,0,0,.04,1.411l0,0a1.007,1.007,0,0,0,1.41-.043l3.2-3.343a1.99,1.99,0,0,0-.007-2.757L13.266.307a1,1,0,0,0-1.4-.042l-.01.01a.986.986,0,0,0-.051,1.4"
        />
      </svg>
    </>
  );
  const elementProps = useLink ? {} : { type: 'button' };
  const element = useLink ? 'a' : 'button';
  return (
    <div className="ContactForm-cta">
      {createElement(
        element,
        {
          ...elementProps,
          ...other,
          className: inverted ? 'ButtonInverted' : 'Button Button--primary',
        },
        childrenFragment
      )}
    </div>
  );
};

export default Button;
