import React, { useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { Formik } from 'formik';
import { Link } from 'gatsby';
import dayjs from 'dayjs';
import { toUpperFirst } from 'ramda-extension';
import FooterLogo from './FooterLogo';
import Contact from './Contact';
import Logo from './Logo';
import withIntl from '../i18n/withIntl';
import WorkshopForm from './WorkshopForm';
import '../../assets/styles/Web.scss';
import Modal from './Modal';
import SubmitButton from './SubmitButton';
import FormikTextField from './FormikTextField';
import { discountFormScheme } from '../utils/validations';
import Button from './Button';
import { WORKSHOP_STATUS } from '../utils/constants';
import { isStringTrue } from '../utils/truthChecks';

const WorkshopRegistration = ({ pageContext }) => {
  const [modalText, setModalText] = useState(undefined);
  const [hasDiscount, setHasDiscount] = useState(false);

  const { discountCode } = pageContext;

  const price = hasDiscount ? pageContext.discountedPrice : pageContext.price;

  const isWorkshop = pageContext.type?.toLowerCase() === 'workshop';

  const handleDiscountSubmit = (values, { resetForm }) => {
    if (
      discountCode &&
      values.discount === discountCode &&
      hasDiscount === false &&
      discountCode !== ''
    ) {
      setHasDiscount(true);
      setModalText(`Slevový kód byl uplatněn.`);
    }
    resetForm();
  };
  return (
    <>
      <section className="Section Section--registration Section--themeLight">
        <div className="Section-main">
          <Logo />
          {pageContext.status === WORKSHOP_STATUS.active && (
            <>
              <h2 className="Section-mainTitle">Registrační formulář</h2>
              <Link to={`/cs/skoleni/${pageContext.topicUrl}`} style={{ textDecoration: 'none' }}>
                <h3 className="Section-linkToDetail">zobrazit detail kurzu</h3>
              </Link>
              <div className="Table Table--registration">
                <div className="Table-row">
                  <div className="Table-cell">Téma workshopu:</div>
                  <div className="Table-cell Table-cell--highlight">
                    {isStringTrue(pageContext.online)
                      ? `Online ${pageContext.type}: ${pageContext.name}`
                      : pageContext.name}
                  </div>
                </div>
                <div className="Table-row">
                  <div className="Table-cell">Datum konání:</div>
                  <div className="Table-cell">{`${pageContext.dateFreeText} v ${pageContext.timeFreeText}`}</div>
                </div>
                <div className="Table-row">
                  <div className="Table-cell">Místo konání:</div>
                  <div className="Table-cell">{pageContext.place}</div>
                </div>
                <div className="Table-row">
                  <div className="Table-cell">Cena:</div>
                  <div className="Table-cell">
                    <FormattedNumber
                      value={price}
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                      currency="CZK"
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                    />
                    &nbsp; s DPH
                  </div>
                </div>
                <div className="Table-row">
                  <div className="Table-margin">
                    <div className="Table-cell">Slevový kód:</div>
                    <div className="Table-cell">
                      {modalText && (
                        <Modal closeModal={() => setModalText(undefined)} text={modalText} />
                      )}
                      <Formik
                        validationSchema={discountFormScheme}
                        onSubmit={handleDiscountSubmit}
                        initialValues={{ discount: '' }}
                      >
                        {({ handleSubmit }) => (
                          <form onSubmit={handleSubmit} className="ContactForm">
                            <div>
                              <div className="Table-textField">
                                <FormikTextField
                                  name="discount"
                                  floatingLabel="codePlaceholder"
                                  autoComplete="off"
                                />
                              </div>
                              <div className="Table-discountButton">
                                <SubmitButton sendMsg="checkCode" />
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="Section-subTitle">Informace o účastníkovi</h3>

              <WorkshopForm hasDiscount={hasDiscount} pageContext={pageContext} />
            </>
          )}
          {pageContext.status === WORKSHOP_STATUS.canceled && (
            <>
              <h2 className="Registration-mainTitle">Workshop je zrušen</h2>
              <div className="Registration">
                <div className="Registration-text">
                  {`${toUpperFirst(pageContext.type)} ${pageContext.name}, kter${
                    isWorkshop ? 'ý' : 'é'
                  } se měl${isWorkshop ? '' : 'o'} konat ${dayjs(pageContext.date).format(
                    'D. M. YYYY'
                  )}, je z
                  organizačních důvodů zrušen${
                    isWorkshop ? '' : 'o'
                  }. Budeme se na Vás těšit na některém z dalších termínů
                  konání, které naleznete pod odkazem níže.`}
                </div>
                <div className="Registration-text Registration-signature">Váš SQN tým</div>
                <div className="Registration-buttonWrapper">
                  <Link to="/cs/#workshop">
                    <Button inverted label="linkToWorkshops" />
                  </Link>
                </div>
              </div>
            </>
          )}
          {pageContext.status === WORKSHOP_STATUS.past && (
            <>
              <h2 className="Registration-mainTitle">Workshop již proběhl</h2>
              <div className="Registration">
                <div className="Registration-text">
                  {`${toUpperFirst(pageContext.type)} ${pageContext.name} již proběhl${
                    isWorkshop ? '' : 'o'
                  } dne ${dayjs(pageContext.date).format(
                    'D. M. YYYY'
                  )}. Budeme se na Vás těšit na některém z dalších workshopů, které naleznete pod odkazem níže.`}
                </div>
                <div className="Registration-text Registration-signature">Váš SQN tým</div>
                <div className="Registration-buttonWrapper">
                  <Link to="/cs/#workshop">
                    <Button inverted label="linkToWorkshops" />
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <footer className="Footer">
        <FooterLogo />
        <Contact />
      </footer>
    </>
  );
};

export default withIntl(WorkshopRegistration, 'cs');
